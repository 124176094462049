import SmartySDK from 'smartystreets-javascript-sdk';
const SmartyCore = SmartySDK.core;
const Lookup = SmartySDK.usAutocompletePro.Lookup;

// Key for SmartyAPI Free Trial
const key = import.meta.env.VITE_SMARTY_API_KEY;
const credentials = new SmartyCore.SharedCredentials(key);

const isProd = import.meta.env.PROD

const clientBuilder = new SmartyCore.ClientBuilder(credentials)
  .withLicenses(['us-autocomplete-pro-cloud']);

export async function lookupAddress(street: string): Promise<SmartySDK.usAutocompletePro.Lookup> {
  // If no key is present, then don't make call so we don't keep getting 401's
  if (key) {
    const client = clientBuilder.buildUsAutocompleteProClient();

    const lookup = new Lookup(street);
    lookup.maxResults = 5;

    if (!isProd) {
      lookup.preferGeolocation = 'none';
    }    

    const result = await client.send(lookup);
    return result;
  }

  return new Lookup(street);
}
