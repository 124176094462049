import { InputComponent } from "../../_components/InputComponent";
import { SelectComponent } from "../../_components/SelectComponent";
import { date_formatter, money_formatter } from "../../_helpers/Masks";
import {
  isValidPaymentDate,
} from "../../_helpers/validations";
import { LeaseBuyoutLoan as LeaseBuyoutLoanType } from "../../_store/loan";
import { isPotentialVulgarFieldValid } from "../../_api/mal-validations";
import { ComponentValidationResult } from "../../_components/IFieldProps";
import { IStoreSelector, useFormStore } from "../../_store/useApplicationState";
import { IApplicationStep } from "../../_store/application";
import { StoreField } from "../../_components/StoreField";
import { MoneyInputComponent } from "../../_components/MoneyInputComponent";
import { MaskedInputComponent } from "../../_components/MaskedInputComponent";
import { NullableDate } from "../../_store/NullableDate";
import { date_parser } from "../../_helpers/parsers";

type LeaseBuyoutLoanProps = IStoreSelector<LeaseBuyoutLoanType> &
  IApplicationStep;


export const LeaseBuyoutLoan = (props: LeaseBuyoutLoanProps) => {
  const minimum_loan = 7999;
  const [store] = useFormStore(app => app.loan_details);
  store.setState({ trade_in: "NoTradeIn", vehicle_type: "UsedVehicle" });
  
  const validate_loan_amount = (): ComponentValidationResult => {
    const { amount, cash_down } = store.getState();

    const loan_amount = (amount ?? 0) - (cash_down ?? 0);
    if (loan_amount < minimum_loan) {
      return [false, () => `You must borrow at least ${money_formatter(minimum_loan)}`];  
    }

    return [true];
  };

  const validateLeaseExpirationDate = (
    next_payment_date: StoreField<Pick<LeaseBuyoutLoanType, "next_payment_date">>,
    force: boolean,
    isDirty: boolean | undefined
  ): ComponentValidationResult => {
    if (!force && !isDirty) {
      return [true];
    }

    if (!next_payment_date) {
      return [false, () => "Lease Expiration Date is required."];
    }

    const valid = isValidPaymentDate(next_payment_date);
    if (!valid) {
      return [
        valid,
        () => "Please enter a valid lease expiration date not in the past.",
      ];
    }

    return [true];
  };

  const validateLeaseHolderAsync = async (
    value: StoreField<Pick<LeaseBuyoutLoanType, "lien_holder">>
  ): Promise<[boolean, (label: string) => string]> => {
    const [isVulgarFree, vulgarErrorMessage] =
      await isPotentialVulgarFieldValid(value);
    if (!isVulgarFree) {
      return [false, vulgarErrorMessage];
    }

    return [true, () => ""];
  };

  return (
    <div className="">
      <h2>Current Lease Details</h2>

      <div className="grid margin-responsive">
        <MoneyInputComponent<Pick<LeaseBuyoutLoanType, "amount">, number | null>
          label="Lease Buyout Amount"
          maxLength={9}
          validate={validate_loan_amount}
          testId="leaseBuyoutAmount"
          className="col"
          infoIcon="Lease Buyout Amount"
          store={props.store}
          selector={(loan) => loan.amount}
          step={props.step}
          helperText={`Buyout amount must be at least ${money_formatter(minimum_loan)}`}
        />

        {/* <InputComponent<Pick<LeaseBuyoutLoanType, "next_payment_date">, string> */}
        {/* <MaskedInputComponent<Pick<LeaseBuyoutLoanType, "next_payment_date">, NullableDate>
          label="Lease Expiration Date"
          inputMode="numeric"
          placeholder=""
          helperText="(mm/dd/yyyy)"
          mask={dateMask}
          validate={validateLeaseExpirationDate}
          infoIcon="Lease Expiration Date"
          store={props.store}
          selector={(loan) => loan.next_payment_date}
          step={props.step}
          formatter={date_formatter}
          field_parser={date_parser}
        ></MaskedInputComponent> */}

        <MaskedInputComponent<Pick<LeaseBuyoutLoanType, "next_payment_date">, NullableDate>
              {...props}
        
              inputMode="numeric"
              formatter={date_formatter}
        
              selector={(loan) => loan.next_payment_date}
              label="Lease Expiration Date"
              infoIcon="Lease Expiration Date"
              helperText="(mm/dd/yyyy)"
              
              validate={validateLeaseExpirationDate}
              field_parser={date_parser}
              // TODO: make better
              to_string={(input) => {
                if (input === "invalid") {
                  // do nothing...
                  return null;
                }
                
                if (input === null) {
                  return "";
                }
        
                const as_string = `${
                  Number(input.getMonth() + 1).toString().padStart(2,'0')
                }/${Number(input.getDate()).toString().padStart(2,'0')}/${input.getFullYear()}`;
        
                return as_string;
              }}
            ></MaskedInputComponent>

        <InputComponent<Pick<LeaseBuyoutLoanType, "lien_holder">, string>
          label="Lease Holder"
          maxLength={50}
          validateAsync={(value) => validateLeaseHolderAsync(value as string)}
          testId="leaseHolder"
          className="col"
          infoIcon="Lease Holder"
          store={props.store}
          selector={(loan) => loan.lien_holder}
          step={props.step}
        />

        <SelectComponent<Pick<LeaseBuyoutLoanType, "term">, string>
          label="New Lease Term"
          className="col"
          store={props.store}
          selector={(loan) => loan.term}
          step={props.step}
        >
          <option></option>
          <option>24</option>
          <option>36</option>
          <option>48</option>
          <option>54</option>
          <option>60</option>
          <option>72</option>
          <option>84</option>
        </SelectComponent>
      </div>
    </div>
  );
};
